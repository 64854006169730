// hooks/useIsMobile.ts
import { useEffect, useState } from 'react';
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const checkIfMobile = () => {
    // Check for viewport width
    const width = window.innerWidth;
    const userAgent = navigator.userAgent.toLowerCase();

    // Use various checks to determine if the device is mobile
    const isMobileViewport = width <= 768; // You can adjust this threshold
    const isMobileUserAgent = /iphone|ipod|ipad|android|blackberry|iemobile|windows phone|mobile/i.test(userAgent);
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    // console.log({
    //   isTouchDevice,
    //   isMobileViewport,
    //   isMobileUserAgent,
    //   userAgent,
    //   width
    // })
    //  || isMobileUserAgent || isTouchDevice
    setIsMobile(isMobileViewport);
  };
  useEffect(() => {
    checkIfMobile(); // Check on initial render
    window.addEventListener('resize', checkIfMobile); // Add event listener

    return () => {
      window.removeEventListener('resize', checkIfMobile); // Cleanup
    };
  }, []);
  return isMobile;
};
export { useIsMobile };